import { registerBlockType } from "@wordpress/blocks";
import {InspectorControls, useBlockProps, InnerBlocks} from "@wordpress/block-editor";

import '../scss/index.scss'

import {PanelBody} from "@wordpress/components";
import {__} from "@wordpress/i18n";
import {useEffect, useState} from "react";

const DropdownPanelBody = ({ title, loading, className, images, image, setOpen, setImage }) => {
    const imagesToView = [ image, ...images.filter(i => i !== image )]

    return <PanelBody title={title}>
        {loading &&
        <span>{__('loading', 'sfat-icon-paragraph')}&hellip;</span>
        ||
        <div className={className} onClick={() => setOpen(true)}>
            <img src={image} className="sfat-icon-paragraph-trigger"/>
            <ul>
                {imagesToView.map(image => {
                    return <li onClick={e => setImage(e, image)}>
                        <img src={image}/>
                    </li>
                })}
            </ul>
        </div>
        }
    </PanelBody>
}

registerBlockType('sfat/icon-paragraph', {
    version: 2,

    title: __('Icon Paragraph', 'sfat-icon-paragraph'),

    description : __('Paragraph with icons on each side', 'sfat-icon-paragraph'),

    supports: {
        align: true
    },

    attributes: {
        imageLeft: {
            type: 'string',
            default: null
        },
        imageRight: {
            type: 'string',
            default: null
        }
    },

    edit({ attributes, setAttributes }) {
        const blockProps = useBlockProps()

        const [loading, setLoading] = useState(true)
        const [open, setAllOpen] = useState([false, false])
        const [images, setImages] = useState([])

        useEffect(() => {
            fetch('/wp-json/plugin/sfat-icon-paragraph/v1/images')
                .then(res => res.json())
                .then(images => {
                    setImages(images)
                    setLoading(false)
                    window.images = images
                })

            document.body.addEventListener('click', onBodyClick)
        }, [])

        const onBodyClick = e => {
            let elem = e.target

            if (elem.classList.contains('sfat-icon-paragraph-trigger')) {
                return
            }

            while (true) {
                if (typeof elem.classList !== 'undefined' && elem.classList.contains('sfat-icon-paragraph')) {
                    return
                }

                if (typeof elem.parentNode !== 'undefined') {
                    break
                }

                elem = elem.parentNode
            }

            setAllOpen([false, false])
        }

        const setOpen = (num, value) => {
            const newValue = [...open]
            newValue[num] = value
            setAllOpen(newValue)
        }

        if (!loading && !attributes.imageLeft) {
            setAttributes({ imageLeft: images[0] })
        }
        if (!loading && !attributes.imageRight) {
            setAttributes({ imageRight: images[0] })
        }

        const setImage = (e, num, image) => {
            e.preventDefault()
            e.stopPropagation()

            const key = num === 0 ? 'imageLeft' : 'imageRight'
            console.log(key, image)

            setAttributes({ [key]: image })
            setOpen(num, false)
        }

        const dropDownClasses = [[ 'sfat-icon-paragraph-dropdown' ], [ 'sfat-icon-paragraph-dropdown' ]]
        if (open[0]) dropDownClasses[0].push('open')
        if (open[1]) dropDownClasses[1].push('open')

        return <div {...blockProps}>
            <InspectorControls>
                <DropdownPanelBody
                    title={__('Left', 'sfat-icon-paragraph')}
                    loading={loading}
                    className={dropDownClasses[0].join(' ')}
                    images={images}
                    image={attributes.imageLeft}
                    setOpen={val => setOpen(0, val)}
                    setImage={(e, image) => setImage(e, 0, image)}
                    />
                <DropdownPanelBody
                    title={__('Right', 'sfat-icon-paragraph')}
                    loading={loading}
                    className={dropDownClasses[1].join(' ')}
                    images={images}
                    image={attributes.imageRight}
                    setOpen={val => setOpen(1, val)}
                    setImage={(e, image) => setImage(e, 1, image)}
                    />
            </InspectorControls>

            <div className="left">
                <img src={attributes.imageLeft}/>
            </div>
            <div className="center">
                <InnerBlocks template={[
                    ['core/heading', { level: 1, placeholder: 'Überschrift', align: 'center', className: 'has-text-align-center' }],
                    ['core/paragraph', {
                        placeholder: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
                        align: "center"
                    }]
                ]}/>
            </div>
            <div className="right">
                <img src={attributes.imageRight}/>
            </div>
        </div>
    },

    save: () => <InnerBlocks.Content/>
})
